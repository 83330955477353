import { Component, HostListener, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { TranslocoModule } from '@jsverse/transloco';
import {
  NgbDropdownModule,
  NgbCollapseModule,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    NgbDropdownModule,
    NgbCollapseModule,
  ],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  authService = inject(AuthService);
  isNavbarCollapsed = true;
  isDropdownOpen = false;

  // Opens the dropdown on mouse enter
  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    this.isDropdownOpen = true;
  }

  // Closes the dropdown on mouse leave
  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    this.isDropdownOpen = false;
  }
}
