import {
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpHandlerFn,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { inject } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { Router } from '@angular/router';

export function ApiErrorInterceptor(
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const router = inject(Router);

  return next(request).pipe(
    tap((event) => {
      // Handle GraphQL-specific errors in successful HTTP responses
      if (event instanceof HttpResponse && isGraphQLRequest(request)) {
        const graphqlResponse = event.body as any;
        if (graphqlResponse?.errors) {
          handleGraphQLErrors(graphqlResponse.errors, authService, router);
        } else {
          next(request);
        }
      }
    }),
    catchError((error: HttpErrorResponse) => {
      // Handle REST API errors (e.g., 401 Unauthorized)
      if (error.status === 401) {
        handleUnauthorized(authService, router);
      }

      // Still check for GraphQL errors if present in error response
      if (isGraphQLRequest(request) && error.error?.errors) {
        handleGraphQLErrors(error.error.errors, authService, router);
      }

      // Rethrow other errors
      return throwError(() => error);
    }),
  );
}

function isGraphQLRequest(request: HttpRequest<unknown>): boolean {
  return request.url.includes('/req');
}

function handleUnauthorized(authService: AuthService, router: Router): void {
  console.error('Unauthorized: clearing state and redirecting to login.');

  // Clear user state
  authService.clearLogState();

  // Redirect to login page if not already there
  if (router.url !== '/login') {
    router.navigate(['/login']);
  }
}

function handleGraphQLErrors(
  errors: any[],
  authService: AuthService,
  router: Router,
): void {
  const unauthenticatedError = errors.find(
    (err) => err.extensions?.code === 'UNAUTHENTICATED',
  );

  if (unauthenticatedError) {
    console.error('GraphQL Unauthenticated Error:', unauthenticatedError);

    // Clear user state
    authService.clearLogState();

    // Redirect to login page if not already there
    if (router.url !== '/login') {
      router.navigate(['/login']);
    }
  }
}
