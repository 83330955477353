import {
  ApplicationConfig,
  provideZoneChangeDetection,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { environment } from '@environments/environment';
import { routes } from './app.routes';
import { provideTransloco, translocoConfig } from '@jsverse/transloco';
import { TranslocoHttpLoader } from '@app/transloco.loader';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { JwtInterceptor } from '@core/interceptors/jwt.interceptor';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { ApiErrorInterceptor } from '@core/interceptors/api-error.interceptor';
import { DatePipe, DecimalPipe, IMAGE_CONFIG } from '@angular/common';
import localeDe from '@angular/common/locales/de'; // Import German locale data
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeDe);
export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    {
      provide: IMAGE_CONFIG,
      useValue: {
        breakpoints: [16, 48, 96, 128, 384, 640, 750, 828, 1080, 1200, 1920],
      },
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory(httpLink: HttpLink) {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: `${environment.apiUrl}${environment.graphqlPath}`, // Combining base URL with GraphQL path
          }),
        };
      },
      deps: [HttpLink],
    },
    Apollo,
    DatePipe,
    DecimalPipe,
    provideAnimations(),
    provideHttpClient(
      withInterceptors([ApiErrorInterceptor, JwtInterceptor, AuthInterceptor]),
    ),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideTransloco({
      config: translocoConfig({
        availableLangs: environment.languages,
        defaultLang: environment.defaultLang,
        reRenderOnLangChange: environment.languages.length > 1,
        prodMode: environment.production,
      }),
      loader: TranslocoHttpLoader,
    }),
  ],
};
